import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

const styles = {
  labelStyle: {
    fontSize: 12,
  },
  defaultWrapperStyle: {
    width: "100%",
    margin: 10,
    marginLeft: 0,
  },
  defaultStyle: {
    padding: "8px 15px",
    borderRadius: 4,
    fontSize: 12,
    fontWeight: "500",
    width: "100%",
    outline: 0,
    transition: "all 100ms",
    backgroundColor: "hsl(0, 0%, 100%)",
    border: "1px solid hsl(0, 0%, 80%)",
  },
};

const { defaultWrapperStyle, defaultStyle, labelStyle } = styles;

const AutoCompleteAddress = ({
  address = "",
  control,
  name,
  error,
  wrapperStyle = {},
  style = {},
  label,
  ...props
}) => {
  const { message } = error || {};

  useEffect(() => {
    setValue(address);
  }, []);

  useEffect(() => {
    const googleMapsScript = document.createElement("script");
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places&callback=YOUR_CALLBACK_NAME`;
    googleMapsScript.defer = true;
    googleMapsScript.async = true;

    document.head.appendChild(googleMapsScript);

    return () => {
      document.head.removeChild(googleMapsScript);
    };
  }, []);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: "YOUR_CALLBACK_NAME",
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const ref = useOnclickOutside(() => clearSuggestions());

  const handleInput = (e, onChange) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  const handleSelect = (suggestion, onChange) => () => {
    const { description } = suggestion;
    setValue(description, false);
    onChange(description);
    clearSuggestions();
  };

  const renderSuggestions = (onChange) =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={handleSelect(suggestion, onChange)}
          className='cursor-pointer'
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <div ref={ref} style={{ ...defaultWrapperStyle, ...wrapperStyle }}>
          {label && <label style={labelStyle}>{label}</label>}
          <input
            value={value}
            onChange={(e) => handleInput(e, onChange)}
            disabled={!ready}
            style={{ ...defaultStyle, ...style }}
            {...props}
          />
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {status === "OK" && (
            <ul className='border p-2'>{renderSuggestions(onChange)}</ul>
          )}

          {message && <p className='text-xs text-red-600'>{message}</p>}
        </div>
      )}
    />
  );
};

export default AutoCompleteAddress;
