import React, { useId, useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

const styles = {
  labelStyle: {
    fontSize: 12,
  },
  defaultWrapperStyle: {
    width: "100%",
    margin: 10,
    marginLeft: 0,
  },
};

const { defaultWrapperStyle, labelStyle } = styles;

function SelectMultipleTags({
  control,
  name,
  error,
  label,
  placeholder,
  wrapperStyle = {},
  style = {},
  selected = [],
  options = [],
  disabled = false,
  ...props
}) {
  const { message } = error || {};
  const [selectedOption, setSelectedOption] = useState(selected);
  const id = useId();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <div
          style={{
            ...defaultWrapperStyle,
            ...wrapperStyle,
            pointerEvents: disabled ? "none" : "all",
          }}
        >
          {label && <label style={labelStyle}>{label}</label>}
          <Select
            instanceId={id}
            selectedValue={selectedOption}
            value={selectedOption}
            onChange={(option) => {
              setSelectedOption(option);
              onChange(option);
              if (props?.cb) props?.cb(option.value);
            }}
            options={options}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral80: "#000",
              },
            })}
            {...props}
          />
          {message && <p className='text-xs text-red-600'>{message}</p>}
        </div>
      )}
    />
  );
}

export default SelectMultipleTags;
