import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Button, Input, TextInput } from "../../components";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrorMsg } from "../../redux/slices/authSlice";
import { instance } from "../../providers/axios";
import toast from "react-hot-toast";
import { Header } from "../../container";

const emailSchema = yup.object({
  email: yup
    .string()
    .nullable()
    .email("Not a valid email")
    .required("Email is required."),
});

function ForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.authUser);

  const [fpLoading, setFpLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
    else dispatch(clearErrorMsg());
  }, [isAuthenticated, dispatch, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(emailSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { email: "" },
  });

  const handleSend = async (data) => {
    try {
      setFpLoading(true);
      await instance.post("/user/password/forgot", data, {
        withCredentials: true,
      });

      toast.success(`Please check you mail for reset password.`);
      setFpLoading(false);
    } catch (error) {
      console.log(error);
      setFpLoading(false);
      toast.error(`Error: ${error.response.data.error}`);
    }
  };

  return (
    <div>
      <Header />

      {/* here it  goes */}
      <div className='bg-white rounded-md p-5 mt-12 mb-8 ml-3 mr-3 max-w-[700px] md:m-auto md:mt-20 md:mb-8'>
        <h1 className='text-xl font-semibold text-center md:text-3xl'>
          Forget Password
        </h1>
        <p className='text-center mt-1 text-sm'>
          Forget Password? Please enter your registered email address.
        </p>

        <form className='mt-5'>
          <TextInput
            control={control}
            placeholder='Enter you registered Email'
            label='Enter you registered Email'
            name='email'
            type='email'
            error={errors.email}
          />

          <Link to='/sign-in'>
            <p
              style={{
                color: "#0000EE",
                marginTop: 5,
                cursor: "pointer",
                textAlign: "right",
              }}
            >
              Go to signin
            </p>
          </Link>

          <div className='ml-auto'>
            <Button
              title='Send Request'
              onClick={handleSubmit(handleSend)}
              loading={fpLoading}
              disabled={fpLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgetPassword;
