import React, { useEffect, useState } from "react";
import "./scss/style.scss";
import { Link, useNavigate } from "react-router-dom";
import { clearErrorMsg, signUp } from "../../redux/slices/authSlice";
import {
  Button,
  CheckboxInput,
  PasswordInput,
  PhoneNumberInput,
  TextInput,
} from "../../components";
import { UserRegisterSchema } from "../../utils/schema";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import generator from "generate-password-browser";

import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../container";

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isAuthenticated, isError, msg } = useSelector(
    (state) => state.authUser
  );
  const [isGeneratePass, setIsGeneratePass] = useState(false);

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
    else dispatch(clearErrorMsg());
  }, [isAuthenticated, dispatch, navigate]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(UserRegisterSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const signupUser = async (data) => {
    const { payload } = await dispatch(signUp(data));

    if (payload?.success) {
      navigate("/");
      toast.success("User has registered successfylly.");
    } else {
      toast.error(payload?.error || "Something went wrong");
    }
  };

  const handleGeneratePassword = () => {
    let passwords = generator.generate({
      length: 20,
      lowercase: true,
      uppercase: true,
      numbers: true,
      symbols: true,
    });

    return passwords;
  };

  return (
    <div>
      <Header />
      {/* <!-- Sign up form --> */}
      <section className='signup'>
        <div className='container'>
          <div className='signup-content'>
            <div className='signup-form'>
              <h2 className='text-xl font-semibold form-title md:text-2xl'>
                Create an Account, and start your Journey with us.
              </h2>
              <form
                className='register-form'
                onSubmit={handleSubmit(signupUser)}
              >
                <TextInput
                  control={control}
                  name='fullName'
                  placeholder='Full Name'
                  label='Full Name'
                  error={errors.fullName}
                />

                <TextInput
                  control={control}
                  name='email'
                  placeholder='Email Address'
                  label='Email Address'
                  error={errors.email}
                  type='email'
                />

                <PhoneNumberInput
                  control={control}
                  name='phoneNumber'
                  placeholder='Phone Number'
                  label='Phone Number'
                  error={errors.phoneNumber}
                />

                <PasswordInput
                  control={control}
                  name='password'
                  placeholder='Password'
                  label='Password'
                  error={errors.password}
                />
                <CheckboxInput
                  control={control}
                  name='password'
                  placeholder='Generate Auto Password'
                  label='Generate Auto Password'
                  value={isGeneratePass}
                  checked={isGeneratePass}
                  onChange={() => {
                    if (isGeneratePass) {
                      setIsGeneratePass(false);
                      setValue("password", "");
                      setValue("confirmPassword", "");
                    } else {
                      const password = handleGeneratePassword();
                      setValue("password", password);
                      setValue("confirmPassword", password);
                      setIsGeneratePass(true);
                    }
                  }}
                />

                <PasswordInput
                  control={control}
                  name='confirmPassword'
                  placeholder='Confirm Password'
                  label='Confirm Password'
                  error={errors.confirmPassword}
                />
                {isError && <p className='errorStyle'>{msg}</p>}

                <Button
                  type='submit'
                  title='Signup'
                  wrapperStyle={{ marginTop: 25 }}
                  style={{ width: "100%" }}
                  loading={loading}
                  disabled={loading}
                />

                <p className='mt-6 text-sm text-center md:text-base md:text-left'>
                  Already have an account?{" "}
                  <Link to='/sign-in'>
                    <span style={{ color: "#0000EE" }}>Sign In</span>
                  </Link>
                </p>
              </form>
            </div>

            <div className='signup-image'>
              <figure>
                <img src='/images/sign-up.svg' alt='sing up' />
              </figure>
              <Link to='/sign-in' className='text-xl md:text-2xl'>
                I am already member
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;
