import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "../../providers/axios";

const initialState = {
  isLoadUserPending: true,
  logggedInUser: null,
  isAuthenticated: false,
  loading: false,
  isError: false,
  msg: "",
};

export const authSlice = createSlice({
  name: "authUser",
  initialState,
  reducers: {
    clearErrorMsg: (state) => {
      state.msg = "";
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    // Login User
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isError = false;

        state.logggedInUser = payload.user;
        state.isAuthenticated = true;
        localStorage.setItem("user_id", payload.user._id);
      })
      .addCase(login.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;

        state.logggedInUser = null;
        state.isAuthenticated = false;
        state.msg = payload?.error || "Something went wrong.";
      });

    // Signup User / Create User
    builder
      .addCase(signUp.pending, (state, action) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(signUp.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isError = false;

        state.isAuthenticated = true;
        state.logggedInUser = payload.user;
        state.msg = payload.message;
        localStorage.setItem("user_id", payload.user._id);
      })
      .addCase(signUp.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        state.msg = payload?.error || "Something went wrong.";
      });

    // Logout
    builder
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.isError = false;
      })
      .addCase(logout.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.isError = false;

        state.logggedInUser = null;
        state.isAuthenticated = false;
        state.msg = payload.message;
        localStorage.removeItem("user_id");
      })
      .addCase(logout.rejected, (state, { payload }) => {
        state.loading = false;
        state.isError = true;
        // state.msg = payload.error;
        console.log(payload);
      });

    // User verifying
    // builder
    //   .addCase(verifyUser.pending, (state, _) => {
    //     state.loading = true;
    //     state.isError = false;
    //   })
    //   .addCase(verifyUser.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.isError = false;

    //     state.logggedInUser = payload.user;
    //     state.msg = payload.message;
    //     localStorage.setItem("user_id", payload.user._id);
    //   })
    //   .addCase(verifyUser.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.isError = true;
    //     state.msg = payload.error || "Something went wrong.";
    //   });

    // GET LOAD USER
    builder
      .addCase(loadUser.pending, (state, { payload }) => {
        state.isLoadUserPending = true;
      })
      .addCase(loadUser.fulfilled, (state, { payload }) => {
        state.isLoadUserPending = false;
        state.logggedInUser = payload.user;
        state.isAuthenticated = true;
        state.isError = false;
        localStorage.setItem("user_id", payload.user._id);
      })
      .addCase(loadUser.rejected, (state) => {
        state.isLoadUserPending = false;
        state.logggedInUser = null;
        state.isAuthenticated = false;
        state.isError = true;
        localStorage.removeItem("user_id");
      });

    // Update address
    builder
      .addCase(updateAddress.fulfilled, (state, { payload }) => {
        state.logggedInUser.address = payload.address;
        state.isError = false;
      })
      .addCase(updateAddress.rejected, (state) => {
        state.logggedInUser.address = {
          fullName: state.logggedInUser?.name,
        };
        state.isError = false;
      });

    // Update Profile Pic
    builder.addCase(updateProfilePic.fulfilled, (state, { payload }) => {
      state.logggedInUser.avatar = payload.avatar;
      state.isError = false;
    });

    // Update USER InFO
    builder
      .addCase(updateUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserInfo.fulfilled, (state, { payload }) => {
        state.logggedInUser = payload.user;
        state.isError = false;
        state.loading = false;
      })
      .addCase(updateUserInfo.rejected, (state) => {
        state.loading = true;
      });
  },
});

export const { clearErrorMsg, checkUserLoaded } = authSlice.actions;
export default authSlice.reducer;

export const signUp = createAsyncThunk(
  "authUser/createUser",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await instance.post("/user/register", body, {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      console.log("authSlice", error);
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const login = createAsyncThunk(
  "authUser/loginUser",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await instance.post("/user/login", body, {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      console.log("error>>>", error, "error.message>>>>", error.message);
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const logout = createAsyncThunk(
  "authUser/logoutUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get("/user/logout", {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

// export const verifyUser = createAsyncThunk(
//   "authUser/verifyUser",
//   async (token, { rejectWithValue }) => {
//     try {
//       const { data } = await instance.post("/auth/verifyemail", {
//         token: `${token}`,
//       });
//       return data;
//     } catch (error) {
//       console.log(error);
//       if (!error.response) {
//         throw error;
//       }

//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const loadUser = createAsyncThunk(
  "authUser/loadUser",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await instance.get("/user/load-user", {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      console.log(error.response.data);
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateUserInfo = createAsyncThunk(
  "authUser/updateUser",
  async (body, { rejectWithValue }) => {
    try {
      const { accountId, ...user } = body;

      const { data } = await instance.patch(
        `/user/update-user-info/${accountId}`,
        user
      );
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

// ====================================================================
// ====================================================================
// ====================================================================
// ====================================================================
// ====================================================================
// ====================================================================

export const updateAddress = createAsyncThunk(
  "authUser/updateAddress",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await instance.post("auth/update-address", body, {
        withCredentials: true,
      });
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);

export const updateProfilePic = createAsyncThunk(
  "authUser/updateProfilePic",
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await instance.post("auth/update-profile", body);
      return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      return rejectWithValue(error.response.data);
    }
  }
);
