import { createBrowserRouter } from "react-router-dom";
import SignIn from "./routes/auth/SignIn";
import SignUp from "./routes/auth/SignUp";
import ForgetPassword from "./routes/auth/ForgetPassword";

import ErrorPage from "./error-page";
import RootLayout from "./routes/RootLayout";
import MyAccount from "./routes/MyAccount/MyAccount";
import Dashboard from "./routes/dashboard/Dashboard";
import QuickQuote from "./routes/shipping/QuickQuote";
import QuoteShip from "./routes/shipping/QuoteShip";
import Orders from "./routes/shipping/Orders";

export const router = createBrowserRouter([
  {
    path: "/sign-up",
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/sign-in",
    element: <SignIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/password/reset/:forgetPasswordToken",
    element: <ForgetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/dashboard/user/account",
        element: <MyAccount />,
      },
      {
        path: "/dashboard/shipping/QuickQuote",
        element: <QuickQuote />,
      },
      {
        path: "/dashboard/shipping/QuoteShip",
        element: <QuoteShip />,
      },
      {
        path: "/dashboard/shipping/Orders",
        element: <Orders />,
      },
    ],
  },
]);
