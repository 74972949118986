import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SideMenu, TopHeader } from "../container";
import { LoadingUser } from "../components";
import { useSelector } from "react-redux";

const RootLayout = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isLoadUserPending } = useSelector(
    (state) => state.authUser
  );
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(true);

  useEffect(() => {
    if (!isLoadUserPending) {
      if (isAuthenticated) navigate(pathname);
      else navigate("/sign-in");
    }
  }, [isLoadUserPending, isAuthenticated, navigate, pathname]);

  if (!isAuthenticated || isLoadUserPending) {
    return <LoadingUser style={{ marginTop: 40 }} />;
  }

  return (
    <div className='ledlogic-dashboard w-full relative'>
      <TopHeader openMenu={openMenu} setOpenMenu={setOpenMenu} />

      <div className='wrapper flex relative'>
        <div
          className={`sideMenu w-[200px] h-full h-min-[700px] overflow-auto border border-black bg-[#42CCCF] fixed top-0 left-0 hidden md:block ${
            !openMenu && "!block md:!hidden"
          }`}
        >
          <SideMenu />
        </div>

        <div className={`w-full ${!openMenu ? "md:ml-0" : "md:ml-[200px]"}`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default RootLayout;
