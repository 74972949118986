import { MdDashboard } from "react-icons/md";
import { FaShippingFast } from "react-icons/fa";

export const dashboardMenu = [
  {
    name: "Dashboard",
    icon: <MdDashboard size={18} color='#ffff' />,
    link: "/dashboard",
  },
  {
    name: "Shipping",
    icon: <FaShippingFast size={18} color='#ffff' />,
    link: "/dashboard/shipping/QuickQuote",

    childrens: [
      {
        name: "Quick Quote",
        link: "/dashboard/shipping/QuickQuote",
      },
      {
        name: "Quote & Ship",
        link: "/dashboard/shipping/QuoteShip",
      },
      {
        name: "Orders",
        link: "/dashboard/shipping/Orders",
      },
    ],
  },
  {
    name: "Manage",
    icon: <FaShippingFast size={18} color='#ffff' />,
    link: "/manage/#HistoryTracking",

    childrens: [
      {
        name: "History & Tracking",
        link: "/manage/#HistoryTracking",
      },
      {
        name: "Address Book",
        link: "/manage/#AddressBook",
      },
      {
        name: "Invoices",
        link: "/manage/#Invoices",
      },
    ],
  },
];
