import React, { useRef } from "react";
import { Link } from "react-router-dom";

import "./header.style.scss";
import { Button } from "../../components";

function Header() {
  const burggerRef = useRef();
  const mainMenuRef = useRef();

  const menuHandler = () => {
    if (burggerRef.current.classList.contains("active")) {
      burggerRef.current.classList.remove("active");
      mainMenuRef.current.classList.remove("active");
    } else {
      burggerRef.current.classList.add("active");
      mainMenuRef.current.classList.add("active");
    }

    const subMenus = document.querySelectorAll(
      ".mainNavbar .navLinks.nav_mob .subMenu"
    );

    Array.from(subMenus).forEach((element) => {
      const subMenu = element.querySelector(".subMenu_navLinks");

      element.querySelector(".subMenuArrow").addEventListener("click", () => {
        subMenu.classList.add("active");
      });

      element.querySelector(".service_arrow").addEventListener("click", () => {
        subMenu.classList.remove("active");
      });
    });
  };

  return (
    <>
      {/* <!-- Top Navbar --> */}
      <nav
        className='navbarTop'
        style={{ backgroundImage: "url(/images/topbar-bg.jpg)" }}
      >
        <div className='container flexDiv'>
          <ul className='flexDiv first'>
            <li>
              <a href='https://discountshippingcanada.com/contact-us'>
                Let's talk
              </a>
            </li>
            <li>|</li>
            <li>
              <a href='#'>Pricing</a>
            </li>
          </ul>

          <ul className='flexDiv secound'>
            <li>
              <a href='tel:+1-1234567890'>+1-1234567890</a>
            </li>
            <li>|</li>
            <li>
              <a href='mailto:ledlogic@hotmail.com'>ledlogic@hotmail.com</a>
            </li>
          </ul>

          {/* <Link to='/sign-in'>
            <Button
              title={"Sign In"}
              btnType='type2'
              style={{ borderRadius: 0, height: 45 }}
            />
          </Link> */}
        </div>
      </nav>

      {/* <!-- Main Navbar --> */}
      <nav className='mainNavbar flexDiv'>
        <div className='container flexDiv'>
          <a href='https://discountshippingcanada.com/'>
            <div className='logo'>
              <img src='/images/logo1.png' alt='logo' />
            </div>
          </a>

          <ul className='navLinks nav_deck flexDiv'>
            <li className='flexDiv'>
              <a
                className='flexDiv'
                href='https://discountshippingcanada.com/about-us'
              >
                About Us
              </a>
            </li>
            <li className='subMenu flexDiv'>
              <a
                className='flexDiv'
                href='https://discountshippingcanada.com/services'
              >
                Services{" "}
                <img
                  className='service_arrow'
                  src='/images/down-arrow.png'
                  alt='down-arrow'
                />
              </a>
              <ul className='subMenu_navLinks'>
                <li>
                  <img
                    className='service_arrow'
                    src='/images/down-arrow.png'
                    alt='down-arrow'
                  />
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/international-shipping-services'>
                    International shipping services
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/u-s-cross-border'>
                    U.S. / Cross-border
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/across-canada'>
                    Across Canada
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/logistics-freight-services'>
                    Logistics Freight Services
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/courier-services'>
                    Courier Services
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/ecommerce-integrations'>
                    eCommerce Integrations
                  </a>
                </li>
                {/* <?php
                        $pageServiceLink = new Wp_Query(array(
                            'posts_per_page'    => -1,
                            'post_type'         => 'service'
                        ));
                        while($pageServiceLink->have_posts()){
                            $pageServiceLink->the_post();
                            ?>
                            <li><a href="<?php echo the_permalink(); ?>"><?php the_title(); ?></a></li>
                        <?php }
                    ?> */}
              </ul>
            </li>
            <li className='subMenu flexDiv'>
              <a
                className='flexDiv'
                href='https://discountshippingcanada.com/shipping-packages-with-ledlogic'
              >
                How it works{" "}
                <img
                  className='service_arrow'
                  src='/images/down-arrow.png'
                  alt='down-arrow'
                />
              </a>
              <ul className='subMenu_navLinks'>
                <li>
                  <img
                    className='service_arrow'
                    src='/images/down-arrow.png'
                    alt='down-arrow'
                  />
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/how-it-works/shipping-packages-with-ledlogic'>
                    SHIPPING PACKAGES WITH EO
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/how-it-works/discount-shipping'>
                    DISCOUNT SHIPPING
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/how-it-works/online-shipping-system'>
                    ONLINE SHIPPING SYSTEM
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/how-it-works/compare-shipping-rates'>
                    COMPARE SHIPPING RATES
                  </a>
                </li>
              </ul>
            </li>
            <li className='subMenu flexDiv'>
              <a className='flexDiv' href='#'>
                Resources{" "}
                <img
                  className='service_arrow'
                  src='/images/down-arrow.png'
                  alt='down-arrow'
                />
              </a>
              <ul className='subMenu_navLinks'>
                <li>
                  <img
                    className='service_arrow'
                    src='/images/down-arrow.png'
                    alt='down-arrow'
                  />
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/about-us'>
                    About Us
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/blogs'>
                    SHIPPING BLOG
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/resources/faqs'>
                    FREQUENTLY ASKED QUESTIONS
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/contact-us'>
                    CONTACT US
                  </a>
                </li>
              </ul>
            </li>

            <Link to='/sign-in'>
              <Button title='LOG IN' btnType='type2' />
            </Link>

            <Link to='/sign-up'>
              <Button title='SIGN UP' />
            </Link>
          </ul>

          <div className='hemBugger' ref={burggerRef} onClick={menuHandler}>
            <span className='line line1'></span>
            <span className='line line2'></span>
            <span className='line line3'></span>
          </div>

          <ul className='navLinks nav_mob flexDiv' ref={mainMenuRef}>
            <li>
              <a href='https://discountshippingcanada.com/about-us'>About Us</a>
            </li>
            <li className='subMenu'>
              <div className='link flexDiv'>
                <a href='https://discountshippingcanada.com/services'>
                  Services
                </a>
                <img
                  className='subMenuArrow'
                  src='/images/down-arrow.png'
                  alt='down-arrow'
                />
              </div>

              <ul className='subMenu_navLinks'>
                <li>
                  <img
                    className='service_arrow'
                    src='/images/down-arrow.png'
                    alt='down-arrow'
                  />
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/international-shipping-services'>
                    International shipping services
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/u-s-cross-border'>
                    U.S. / Cross-border
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/across-canada'>
                    Across Canada
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/logistics-freight-services'>
                    Logistics Freight Services
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/courier-services'>
                    Courier Services
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/services/ecommerce-integrations'>
                    eCommerce Integrations
                  </a>
                </li>
              </ul>
            </li>
            <li className='subMenu'>
              <div className='link flexDiv'>
                <a href='https://discountshippingcanada.com/shipping-packages-with-ledlogic'>
                  How it works
                </a>
                <img
                  className='subMenuArrow'
                  src='/images/down-arrow.png'
                  alt='down-arrow'
                />
              </div>
              <ul className='subMenu_navLinks'>
                <li>
                  <img
                    className='service_arrow'
                    src='/images/down-arrow.png'
                    alt='down-arrow'
                  />
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/shipping-packages-with-ledlogic'>
                    SHIPPING PACKAGES WITH EO
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/discount-shipping'>
                    DISCOUNT SHIPPING
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/our-online-shipping-system'>
                    ONLINE SHIPPING SYSTEM
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/compare-shipping-rates'>
                    COMPARE SHIPPING RATES
                  </a>
                </li>
              </ul>
            </li>
            <li className='subMenu'>
              <div className='link flexDiv'>
                <a href='#'>Resources</a>
                <img
                  className='subMenuArrow'
                  src='/images/down-arrow.png'
                  alt='down-arrow'
                />
              </div>
              <ul className='subMenu_navLinks'>
                <li>
                  <img
                    className='service_arrow'
                    src='/images/down-arrow.png'
                    alt='down-arrow'
                  />
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/about-us'>
                    About Us
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/blogs'>
                    SHIPPING BLOG
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/resources/faqs'>
                    FREQUENTLY ASKED QUESTIONS
                  </a>
                </li>
                <li>
                  <a href='https://discountshippingcanada.com/contact-us'>
                    CONTACT Us
                  </a>
                </li>
              </ul>
            </li>

            <a to='https://discountshippingcanada.com/contact-us'>
              <button className='btn'>Contact us</button>
            </a>

            <div className='flexDiv gap-2 flex-wrap'>
              <Link to='/sign-in'>
                <Button title='Sign In' />
              </Link>
              <Link to='/sign-up'>
                <Button title='Sign up' btnType='type2' />
              </Link>
            </div>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header;
