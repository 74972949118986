import React from "react";
import Loader from "../loader/Loader";

const LoadingUser = ({ style }) => {
  return (
    <div
      style={{
        margin: "auto",
        maxWidth: 400,
        border: "1px solid #000",
        padding: 20,
        borderRadius: 10,
        ...style,
      }}
    >
      <p
        style={{ marginTop: 0, marginBottom: 20 }}
        className='text-base font-semibold'
      >
        User is authenticating, Please wait ...
      </p>
      <Loader />
    </div>
  );
};

export default LoadingUser;
