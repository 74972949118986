import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import StoreProvider from "./providers/StoreProvider";
import { Toaster } from "react-hot-toast";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

const toastOptions = {
  // Define default options
  className: "",
  duration: 5000,
  style: {
    background: "#363636",
    color: "#fff",
    zIndex: 99999,
  },

  // Default options for specific types
  success: {
    duration: 5000,
    theme: {
      primary: "green",
      secondary: "black",
    },
  },
};

root.render(
  <React.StrictMode>
    <StoreProvider>
      <App />
      <Toaster
        position='top-center'
        reverseOrder={false}
        gutter={8}
        toastOptions={toastOptions}
      />
    </StoreProvider>
  </React.StrictMode>
);
