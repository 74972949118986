import { ClipLoader } from "react-spinners";
import styles from "./Button.module.scss";

const { button, hidden, spinner, type2Button, type3Button } = styles;

const Button = ({
  title,
  style,
  wrapperStyle,
  loading = false,
  btnType = "type1",
  icon,
  type,
  ...props
}) => {
  return (
    <div className={loading ? hidden : undefined} style={wrapperStyle}>
      {loading && (
        <div className={spinner}>
          <ClipLoader color='#36d7b7' />
        </div>
      )}

      <button
        type={type}
        className={`${button} ${btnType === "type2" && type2Button} ${
          btnType === "type3" && type3Button
        }`}
        style={style}
        {...props}
      >
        {icon && icon}
        <span>{title}</span>
      </button>
    </div>
  );
};

export default Button;
