import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CgMenuGridR } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/slices/authSlice";
import toast from "react-hot-toast";

const TopHeader = ({ setOpenMenu, openMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logggedInUser } = useSelector((state) => state.authUser);
  const [openAccountMenu, setOpenAccountMenu] = useState(false);

  const logoutHandler = async () => {
    try {
      await dispatch(logout());
      navigate("/sign-in");
      toast.success("Logged Out!");
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className='topmenu flex justify-between items-center w-full h-[70px] pl-5 pr-5 sticky top-0 z-50 bg-white'>
      <ul className='flex justify-start items-center gap-3'>
        <li className='cursor-pointer'>
          <CgMenuGridR size={25} onClick={() => setOpenMenu(!openMenu)} />
        </li>
        <li className='cursor-pointer'>
          <Link to='/'>
            <div className='logo w-[80px]'>
              <img
                src='/images/logo1.png'
                alt='logo'
                className='w-full h-full object-contain'
              />
            </div>
          </Link>
        </li>
      </ul>

      <ul>
        <li
          className='relative'
          tabIndex={0}
          onBlur={() => {
            setTimeout(() => {
              setOpenAccountMenu(false);
            }, "200");
          }}
        >
          <div
            className='flex gap-3 items-center cursor-pointer'
            onClick={() => setOpenAccountMenu(!openAccountMenu)}
          >
            <img
              src='/images/avatar.avif'
              alt='avatar'
              className='w-[40px] h-[40px] rounded-full'
            />
            <div className='flex flex-col'>
              <span className='font-medium'>{logggedInUser?.fullName}</span>
              <span>{logggedInUser?.email}</span>
            </div>
          </div>

          {openAccountMenu && (
            <div className='absolute top-[60px] p-3 bg-white w-full rounded-md border border-t-0 border-b-4 border-blue-200'>
              <p className='flex flex-col mb-2'>
                <span className='text-[#42CCCF] text-xs'>Welcome</span>
                <span className='font-medium'>{logggedInUser?.fullName}</span>
              </p>

              <p className='flex gap-2 text-xs'>
                <span>Account:</span>{" "}
                <span className='text-red-600'>
                  {logggedInUser?._id.slice(0, 12)}...
                </span>
              </p>

              <hr className='mt-2' />

              <ul className='mt-2 flex flex-col'>
                <Link to='/dashboard/user/account'>
                  <li className='hover:bg-slate-100 p-2 cursor-pointer'>
                    My Info
                  </li>
                </Link>
                <li
                  className='hover:bg-slate-100 p-2 cursor-pointer'
                  onClick={logoutHandler}
                >
                  LogOut
                </li>
              </ul>
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default TopHeader;
