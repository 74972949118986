import React from "react";
import { Controller } from "react-hook-form";

const styles = {
  defaultWrapperStyle: {
    width: "100%",
    margin: 10,
    marginLeft: 0,
    display: "flex",
    alignItems: "center",
    justifyContnet: "center",
    gap: 8,
  },
  defaultStyle: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "500",
    width: 18,
    height: 18,
    outline: 0,
    transition: "all 100ms",
    backgroundColor: "hsl(0, 0%, 100%)",
    border: "1px solid hsl(0, 0%, 80%)",
  },
};

const { defaultWrapperStyle, defaultStyle } = styles;

function CheckboxInput({
  control,
  name,
  error,
  placeholder,
  label,
  wrapperStyle = {},
  style = {},
  showFun = () => {},
  ...props
}) {
  const { message } = error || {};

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          <div style={{ ...defaultWrapperStyle, ...wrapperStyle }}>
            <input
              type='checkbox'
              id='checkboxInput'
              value={value ? value : false}
              checked={value ? value : false}
              onChange={(e) => {
                onChange(e);
                showFun(e);
              }}
              style={{ ...defaultStyle, ...style }}
              {...props}
            />
            <label htmlFor='checkboxInput'>{label || placeholder}</label>
          </div>
          {message && <p className='text-xs text-red-600'>{message}</p>}
        </>
      )}
    />
  );
}

export default CheckboxInput;
