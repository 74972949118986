import React, { useEffect } from "react";
import "./scss/style.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  CheckboxInput,
  PasswordInput,
  TextInput,
} from "../../components";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { clearErrorMsg, login } from "../../redux/slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../container";

/* schema */
const schema = yup
  .object({
    email: yup
      .string()
      .required("Email is required")
      .email("Not a valid email"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Must contain at least 8 characters"),
    remember: yup.boolean(),
  })
  .required();

export default function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, isAuthenticated, isError, msg } = useSelector(
    (state) => state.authUser
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { email: "", password: "" },
  });

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
    else dispatch(clearErrorMsg());
  }, [isAuthenticated, isDirty, dispatch, navigate]);

  const loginUser = async (data) => {
    try {
      const { payload } = await dispatch(login(data));

      if (payload?.success) {
        toast.success("Logged in successfylly.");
      }
    } catch (error) {
      console.log("error", error);
      toast.error(error.message || "Something went wrong.");
    }
  };

  return (
    <div className=''>
      <Header />

      {/* <!-- Sing in  Form --> */}
      <section className='sign-in'>
        <div className='container'>
          <div className='signin-content'>
            <div className='signin-image'>
              <figure>
                <img src='/images/sign-in.svg' alt='singIn' />
              </figure>

              <Link to='/sign-up' className='text-xl md:text-2xl'>
                Create an account
              </Link>
            </div>

            <div className='signin-form'>
              <h2 className='text-3xl font-semibold form-title'>
                Welcome Back, <br /> Sign in here.
              </h2>
              <form
                className='register-form'
              >
                <TextInput
                  control={control}
                  placeholder='Email Address'
                  label='Email Address'
                  name='email'
                  type='email'
                  error={errors.email}
                />

                <PasswordInput
                  control={control}
                  placeholder='Password'
                  label='Password'
                  name='password'
                  error={errors.password}
                />

                <CheckboxInput
                  control={control}
                  placeholder='Remember'
                  label='Remember'
                  name='remember'
                  error={errors.remember}
                  // showFun={(e) => setIsRemember(e.target.checked)}
                />
                {isError && <p className='text-red-500'>{msg}</p>}

                <Link to='/forget-password'>
                  <p
                    style={{
                      color: "#0000EE",
                      marginTop: 5,
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                  >
                    Forget Password
                  </p>
                </Link>

                <Button
                  type='button'
                  title='Sign In'
                  wrapperStyle={{ marginTop: 15, width: "100%" }}
                  style={{ width: "100%" }}
                  loading={loading}
                  disabled={loading}
                  onClick={handleSubmit(loginUser)}
                />

                <p className='mt-6 text-sm text-center md:text-base md:text-left'>
                  Don&apos;t have an account.
                  <Link to='/sign-up'>
                    <span style={{ color: "#0000EE" }}>Sign up</span>
                  </Link>
                </p>
              </form>

              {/* <br />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                <span style={{ flex: 1, height: 1, backgroundColor: "gray" }} />
                <span>Or Sign in with Google</span>
                <span style={{ flex: 1, height: 1, backgroundColor: "gray" }} />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
