import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import { dashboardMenu } from "../../utils/static-data";

function SideMenu() {
  const { pathname } = useLocation();

  return (
    <Fragment>
      <ul className='p-2 flex flex-col gap-3 mt-[70px]'>
        {dashboardMenu?.map((menu, idx) => (
          <li key={idx}>
            <Link to={menu.link}>
              <div
                className={`flex gap-2 items-center cursor-pointer pl-3 pt-1 pr-3 pb-1 rounded-2xl ${
                  pathname === menu.link && "bg-[#30b1b3]"
                }`}
              >
                <span>{menu.icon}</span>
                <span className='text-white font-medium text-sm'>
                  {menu.name}
                </span>
              </div>
            </Link>

            {menu.childrens?.length > 0 && (
              <ul className='ml-5 mt-1 flex flex-col gap-1'>
                {menu.childrens.map((subMenu, idx) => (
                  <Link to={subMenu.link} key={idx}>
                    <li
                      key={idx}
                      className={`text-white text-sm font-medium pl-3 pt-1 pr-3 pb-1 rounded-2xl ${
                        pathname === subMenu.link && "bg-[#30b1b3]"
                      }`}
                    >
                      {subMenu.name}
                    </li>
                  </Link>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </Fragment>
  );
}

export default SideMenu;
