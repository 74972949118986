import React from "react";
import { Controller } from "react-hook-form";
// import { Rating } from "@smastrom/react-rating";
// import "@smastrom/react-rating/style.css";

const styles = {
  labelStyle: {
    fontSize: 12,
  },
  defaultWrapperStyle: {
    width: "100%",
    margin: 10,
    marginLeft: 0,
  },
};

const { defaultWrapperStyle, labelStyle } = styles;

function TextInput({
  control,
  name,
  error,
  placeholder,
  label,
  wrapperStyle = {},
  style = {},
  ...props
}) {
  const { message } = error || {};
  // const [rating, setRating] = useState(0);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <div style={{ ...defaultWrapperStyle, ...wrapperStyle }}>
          {label && <label style={labelStyle}>{label}</label>}
          {/* <Rating
            value={value || 0}
            onChange={(e: any) => {
              onChange(e);
              setRating(e);
            }}
            items={5}
            isRequired
            style={{ maxWidth: 250 }}
            {...props}
          /> */}
          {message && <p className='text-xs text-red-600'>{message}</p>}
        </div>
      )}
    />
  );
}

export default TextInput;
