import React from "react";

function QuickShip() {
  return (
    <div className='bg-white min-h-[100vh] rounded-md border-[0.5px] p-2 m-2 md:p-3 md:m-7'>
      <h1>QuickShip</h1>
    </div>
  );
}

export default QuickShip;
