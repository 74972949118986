import React from "react";

const styles = {
  defaultWrapperStyle: {
    width: "100%",
    margin: 10,
    marginLeft: 0,
    display: "flex",
    alignItems: "center",
    justifyContnet: "center",
    gap: 8,
  },
  defaultStyle: {
    borderRadius: 4,
    fontSize: 16,
    fontWeight: "500",
    width: 18,
    height: 18,
    outline: 0,
    transition: "all 100ms",
    backgroundColor: "hsl(0, 0%, 100%)",
    border: "1px solid hsl(0, 0%, 80%)",
  },
};

const { defaultWrapperStyle, defaultStyle } = styles;

function CheckboxInput({
  label,
  placeholder,
  wrapperStyle = {},
  style = {},
  ...props
}) {
  return (
    <div style={{ ...defaultWrapperStyle, ...wrapperStyle }}>
      <input
        type='checkbox'
        id='checkboxInput'
        style={{ ...defaultStyle, ...style }}
        {...props}
      />
      <label htmlFor='checkboxInput'>{label || placeholder}</label>
    </div>
  );
}

export default CheckboxInput;
