import React, { useEffect, useState } from "react";
import { CiCircleQuestion } from "react-icons/ci";
import { MyInfoSchema } from "../../utils/schema";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

import {
  AutoCompleteAddress,
  Button,
  CheckboxInput,
  PhoneNumberInput,
  SelectMultipleTags,
  TextInput,
  Tooltip,
} from "../../components";
import { Country, State } from "country-state-city";
import { updateUserInfo } from "../../redux/slices/authSlice";

const defaultValues = {
  fullName: "",
  companyName: "",
  notaBusiness: false,
  email: "",
  phoneNumber: "",
  residential: false,
  address: {
    address1: "",
    apptNo: "",
    address2: "",
    city: "",
    zip: "",
    province: {
      label: "",
      value: "",
    },
    country: {
      label: "",
      value: "",
    },
  },
  socialLinks: {
    websiteUrl: "",
    websiteSupportUrl: "",
    fbUrl: "",
    igUrl: "",
    linkedinUrl: "",
    twitterUrl: "",
  },
};

function MyAccount() {
  const dispatch = useDispatch();
  const { loading, logggedInUser } = useSelector((state) => state.authUser);

  const [notaBusiness, setNotaBusiness] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [statesList, setStatesList] = useState([]);

  const countriesList = Country.getAllCountries().map((country) => ({
    value: country.isoCode,
    label: country.name,
  }));

  useEffect(() => {
    if (!countryCode) return;

    setStatesList(() => {
      const states = State.getStatesOfCountry(countryCode);
      return states?.map((state) => ({
        value: state.countryCode,
        label: state.name,
      }));
    });
  }, [countryCode]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(MyInfoSchema),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    reset({
      accountId: logggedInUser?._id,
      fullName: logggedInUser?.fullName,
      companyName: logggedInUser?.companyName,
      notaBusiness: logggedInUser?.notaBusiness,
      email: logggedInUser?.email,
      phoneNumber: logggedInUser?.phoneNumber,
      residential: logggedInUser?.residential,
      address: logggedInUser?.address,
      socialLinks: logggedInUser?.socialLinks,
    });

    setNotaBusiness(logggedInUser?.notaBusiness);
  }, [logggedInUser, reset]);

  const handleUpdateAccountInfo = async (data) => {
    const { notaBusiness } = data;
    if (notaBusiness) data.companyName = "";

    const { payload } = await dispatch(updateUserInfo(data));

    if (payload?.success) {
      toast.success(payload.message);
    } else {
      toast.error(payload.error);
    }
  };

  return (
    <div className='bg-white rounded-md border-[0.5px] p-2 m-2 md:p-3 md:m-7'>
      <h2 className='text-lg font-semibold w-full p-2 border-b border-black md:text-xl'>
        My Info
      </h2>

      <form onSubmit={handleSubmit(handleUpdateAccountInfo)}>
        <div className='flex flex-col gap-2 mt-5'>
          <h2 className='text-base font-semibold md:text-lg'>
            Basic contact details
          </h2>

          <div className='flex flex-wrap w-full'>
            <TextInput
              control={control}
              placeholder='Account ID'
              label='Account ID'
              name='accountId'
              error={errors.accountId}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
              readOnly
            />

            <TextInput
              control={control}
              placeholder='Company Name'
              label='Company Name'
              name='companyName'
              error={errors.companyName}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
              readOnly={notaBusiness}
            />

            <CheckboxInput
              control={control}
              placeholder='Not a business'
              label='Not a business'
              name='notaBusiness'
              error={errors.notaBusiness}
              showFun={(e) => setNotaBusiness(e.target.checked)}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />

            <TextInput
              control={control}
              placeholder='Full Name'
              label='Full Name'
              name='fullName'
              error={errors.fullName}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />

            <TextInput
              control={control}
              placeholder='Email Address'
              label='Email Address'
              name='email'
              type='email'
              error={errors.email}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />

            <PhoneNumberInput
              control={control}
              placeholder='Phone Number'
              label='Phone Number'
              name='phoneNumber'
              error={errors.phoneNumber}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />

            <CheckboxInput
              control={control}
              placeholder='Residential'
              label='Residential'
              name='residential'
              error={errors.residential}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />
          </div>
        </div>

        <div className='flex flex-col mt-5'>
          <h2 className='text-base font-semibold md:text-lg'>
            Address Details
          </h2>
          <div className='flex gap-2'>
            <p className='text-sm'>Only Canada Post & USPS accept PO Boxes</p>
            <span>
              <Tooltip
                text='Shipments sent to P.O. boxes by couriers other than Canada Post or USPS will be returned to sender and return shipping costs will be applied.'
                style={{ top: "calc(-100% - 80px)" }}
              >
                <CiCircleQuestion />
              </Tooltip>
            </span>
          </div>

          <div className='flex flex-wrap w-full'>
            <AutoCompleteAddress
              control={control}
              placeholder='Address'
              label='Address'
              name='address.address1'
              address={logggedInUser?.address?.address1}
              error={errors.address?.address1}
              wrapperStyle={{ maxWidth: 400, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Unit No. / Appt. No.'
              label='Unit No. / Appt. No.'
              name='address.apptNo'
              error={errors.address?.apptNo}
              wrapperStyle={{ maxWidth: 400, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Address 2'
              label='Address 2 (Optional)'
              name='address.address2'
              error={errors.address?.address2}
              wrapperStyle={{ maxWidth: 400, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='City'
              label='City'
              name='address.city'
              error={errors.address?.city}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Zip Code / Postal Code'
              label='Postal Code / Zip Code'
              name='address.zip'
              error={errors.address?.zip}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
            />
            <SelectMultipleTags
              control={control}
              placeholder='Country'
              label='Country'
              name='address.country'
              error={errors.address?.country?.label}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
              isMulti={false}
              isSearchable={true}
              selected={logggedInUser?.address?.country}
              options={countriesList}
              cb={setCountryCode}
            />
            <SelectMultipleTags
              control={control}
              placeholder='Province / State'
              label='Province / State'
              name='address.province'
              error={errors.address?.province?.label}
              wrapperStyle={{ maxWidth: 250, minWidth: 200 }}
              isMulti={false}
              isSearchable={true}
              selected={logggedInUser?.address?.province}
              options={statesList}
            />
          </div>
        </div>

        <div className='flex flex-col mt-5'>
          <h2 className='text-base font-semibold md:text-lg'>
            Additional details
          </h2>

          <div className='flex flex-wrap w-full'>
            <TextInput
              control={control}
              placeholder='Website Url'
              label='Website Url'
              name='socialLinks.websiteUrl'
              error={errors.socialLinks?.websiteUrl}
              wrapperStyle={{ maxWidth: 300, minWidth: 200 }}
            />

            <TextInput
              control={control}
              placeholder='Website Support Url'
              label='Website Support Url'
              name='socialLinks.websiteSupportUrl'
              error={errors.socialLinks?.websiteSupportUrl}
              wrapperStyle={{ maxWidth: 300, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Facebook Url'
              label='Facebook Url'
              name='socialLinks.fbUrl'
              error={errors.socialLinks?.fbUrl}
              wrapperStyle={{ maxWidth: 300, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Instagram Url'
              label='Instagram Url'
              name='socialLinks.igUrl'
              error={errors.socialLinks?.igUrl}
              wrapperStyle={{ maxWidth: 300, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Linkedin Url'
              label='Linkedin Url'
              name='socialLinks.linkedinUrl'
              error={errors.socialLinks?.linkedinUrl}
              wrapperStyle={{ maxWidth: 300, minWidth: 200 }}
            />
            <TextInput
              control={control}
              placeholder='Twitter Url'
              label='Twitter Url'
              name='socialLinks.twitterUrl'
              error={errors.socialLinks?.twitterUrl}
              wrapperStyle={{ maxWidth: 300, minWidth: 200 }}
            />
          </div>
        </div>

        <div className='mt-7'>
          <Button title='Update Info' loading={loading} disabled={loading} />
        </div>
      </form>
    </div>
  );
}

export default MyAccount;
