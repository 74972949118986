import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";

import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "./redux/slices/authSlice";

function App() {
  const { isAuthenticated } = useSelector((state) => state.authUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuthenticated) dispatch(loadUser());
  }, [dispatch, isAuthenticated]);

  return <RouterProvider router={router} />;
}

export default App;
